import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import { signOut } from '../firebase';

function NavBar() {
    const { currentUser } = useAuth();
    const navigate = useNavigate();

    const handleSignOut = async () => {
        await signOut();
        navigate('/'); // Redirect to the landing page after sign out
    };

    return (
        <nav className="bg-navColor p-4">
            <div className="container mx-auto flex justify-between items-center">
                <NavLink to="/" className="text-black text-2xl font-bold font-chewy">
                    Squirrel
                </NavLink>
                <div className="flex space-x-8 items-center">
                    {currentUser && currentUser.displayName && (
                        <a href="#learn-more" className="text-black font-semibold">
                            Welcome, {currentUser.displayName}
                        </a>
                    )}
                    <a href="#learn-more" className="text-black hover:text-white font-semibold">Learn More</a>
                    {currentUser ? (
                        <>
                            <NavLink to="/dashboard" className="text-black hover:text-white font-semibold">Dashboard</NavLink>
                            <button onClick={handleSignOut} className="text-black hover:text-white font-semibold">
                                Sign Out
                            </button>
                        </>
                    ) : (
                        <>
                            <NavLink to="/login" className="text-black hover:text-white font-semibold">Login</NavLink>
                            <NavLink to="/signup" className="text-black hover:text-white font-semibold border border-white rounded-full px-2 py-1 bg-white">
                                Sign Up
                            </NavLink>
                        </>
                    )}
                </div>
            </div>
        </nav>
    );
}

export  default NavBar;