import React from 'react';
import { useNavigate } from 'react-router-dom'; // Make sure to import useNavigate

function DashboardPage() {
    const navigate = useNavigate(); // Initialize navigate using the useNavigate hook

    return (
        <div className="min-h-screen bg-bodyColor p-8">
            <h1 className="text-3xl font-bold text-gray-800">Dashboard</h1>
            <p className="mt-4 mb-8 text-lg text-gray-600">Welcome to your dashboard!</p>
            <div className="grid grid-cols-4 gap-4">
                {/* Each tile is now clickable and has centered text */}
                <button className="p-4 shadow hover:bg-gray-200 rounded-full px-2 bg-white flex justify-center items-center text-center" onClick={() => {/* Define action here */}}>Add a profile</button>
                <button className="p-4 shadow hover:bg-gray-200 rounded-full px-2 bg-white flex justify-center items-center text-center" onClick={() => navigate('/dashboard/add-document')}>Add a document</button>
                <button className="p-4 shadow hover:bg-gray-200 rounded-full px-2 bg-white flex justify-center items-center text-center" onClick={() => {/* Define action here */}}>View documents</button>
                <button className="p-4 shadow hover:bg-gray-200 rounded-full px-2 bg-white flex justify-center items-center text-center" onClick={() => {/* Define action here */}}>Manage users</button>
            </div>
            <div className="mt-8">
                <h2 className="text-2xl font-bold text-gray-800">Recent Documents</h2>
                <div className="mt-4 bg-white p-4 shadow rounded">
                    <p>Document 1 - Last accessed on [date]</p>
                    <p>Document 2 - Last accessed on [date]</p>
                </div>
            </div>
        </div>
    );
}

export default DashboardPage;