import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import NavBar from './components/Navbar';
import SignUpPage from './components/SignUpPage';
import DashboardPage from './components/Dashboard/DashboardPage';
import AddDocumentPage from './components/Dashboard/AddDocumentPage';
import LandingPage from './components/LandingPage';
import Login from './components/LoginPage';

function App() {
    return (
        <Router>
          <NavBar />
            <Routes>
                <Route path="/signup" element={<SignUpPage />} />
                <Route path="/dashboard" element={<DashboardPage />} />
                <Route path="/dashboard/add-document" element={<AddDocumentPage />} />
                <Route path="/login" element={<Login />} />
                <Route path="/" element={<LandingPage />} />
            </Routes>
        </Router>
    );
}

export default App;