import React from 'react';
import bannerImage from '../images/landingImage.png';
import acronImage from '../images/acorn.png';

function LandingPage() {

    return (
        <div className="w-full min-h-screen p-0 m-0">
            <div className="relative w-full" style={{ height: '72vh' }}>
                <img src={bannerImage} alt="Landing Page" className="w-full h-full object-cover" />
            </div>
            <div className="text-center p-8 bg-bodyColor">
                <h1 className="text-5xl font-chewy mb-4">Gather all of your policies in one place</h1>
                <div className="flex justify-center items-start space-x-6">
                    <div className="flex justify-between w-full">
                        {/* Conditional rendering based on authentication state */}
                            <div className="flex flex-col items-center w-1/3">
                                <img src={acronImage} alt="Policy Information" className="w-20 h-20 mt-4 mb-2" />
                                <span className="break-words mb-4 font-chewy"> 📄 Access all the information related to any and all policies</span>
                                <div className="mt-auto">
                                    <button className="bg-buttonColor text-[#e8e7e2] hover:bg-gray-300 px-8 py-4 rounded-full flex justify-center items-center space-x-3 text-lg font-chewy">
                                        <span>Sign Up</span>
                                    </button>
                                </div>
                            </div>
                        <div className="flex flex-col items-center w-1/3">
                            <img src={acronImage} alt="Policy Information" className="w-20 h-20 mt-4 mb-2" />
                            <span className="break-words mb-4 font-chewy"> 💬 Ask squirrel questions about any policy</span>
                            <div className="mt-auto">
                                <button className="bg-buttonColor text-[#e8e7e2] hover:bg-gray-300 px-8 py-4 rounded-full flex justify-center items-center space-x-3 text-lg font-chewy">
                                    <span>Learn More</span>
                                </button>
                            </div>
                        </div>
                        <div className="flex flex-col items-center w-1/3">
                            <img src={acronImage} alt="Policy Information" className="w-20 h-20 mt-4 mb-2" />
                            <span className="break-words mb-4 font-chewy">🔍 Identify and address policy gaps</span>
                            <div className="mt-auto">
                                <button className="bg-buttonColor text-[#e8e7e2] hover:bg-gray-300 px-8 py-4 rounded-full flex justify-center items-center space-x-3 text-lg font-chewy">
                                    <span>Sign Up</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LandingPage;