import { initializeApp } from 'firebase/app';
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, updateProfile as firebaseUpdateProfile, signOut as firebaseSignOut } from 'firebase/auth';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
    apiKey: "AIzaSyDjh1HxcVdtsyAX1EvuHDvAwkiLCcKRUcU",
    authDomain: "squirrel-da631.firebaseapp.com",
    projectId: "squirrel-da631",
    storageBucket: "squirrel-da631.appspot.com",
    messagingSenderId: "166456585472",
    appId: "1:166456585472:web:069fd9a7232eadfc8e57e5",
    measurementId: "G-V9BHZF2Z55"
  };

  const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const storage = getStorage(app);

// Define the signOut function
const signOut = async () => {
    try {
        await firebaseSignOut(auth);
    } catch (error) {
        console.error('Error signing out: ', error);
    }
};

export { auth, storage, createUserWithEmailAndPassword, signInWithEmailAndPassword, firebaseUpdateProfile as updateProfile, signOut };