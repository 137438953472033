import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { auth } from '../../firebase';
import { FaFileAlt } from 'react-icons/fa'; // Ensure react-icons is installed
import { useNavigate } from 'react-router-dom'; // Import useNavigate instead of useHistory
import Lottie from 'lottie-react';
import squirrelAnimation from '../../animations/loadingSquirrel.json';

function AddDocumentPage() {
    const [selectedFile, setSelectedFile] = useState(null);
    const [uploadSuccess, setUploadSuccess] = useState(false);
    const [isLoading, setIsLoading] = useState(false); // Add loading state
    const navigate = useNavigate(); // useNavigate instead of useHistory

    const { getRootProps, getInputProps } = useDropzone({
        onDrop: acceptedFiles => {
            setSelectedFile(acceptedFiles[0]);
            setUploadSuccess(false);
        }
    });

    const handleUpload = async () => {
        if (!selectedFile) return;

        const user = auth.currentUser;
        if (!user) {
            console.error('User not authenticated');
            return;
        }

        const firebaseToken = await user.getIdToken();
        const formData = new FormData();
        formData.append('file', selectedFile);
        formData.append('token', firebaseToken);

        setIsLoading(true); // Set loading to true before starting upload

        try {
            const response = await fetch('http://127.0.0.1:5000/upload', {
                method: 'POST',
                body: formData,
            });

            if (response.ok) {
                console.log('File uploaded successfully');
                setUploadSuccess(true);
            } else {
                console.error('Failed to upload file');
            }
        } catch (error) {
            console.error('Error uploading file:', error);
        } finally {
            setIsLoading(false); // Set loading to false after upload completes
        }
    };

    const handleReset = () => {
        setSelectedFile(null);
        setUploadSuccess(false);
    };

    return (
        <div className="container mx-auto mt-10">
            <h1 className="text-4xl font-bold mb-6">Add Document</h1>
            <div className="mb-6">
                <label htmlFor="fileUpload" className="block text-lg font-medium text-gray-700">Upload File:</label>
                <div {...getRootProps({ className: 'dropzone flex justify-center items-center border-2 border-gray-300 border-dashed rounded-md' })} style={{ height: '200px', cursor: 'pointer' }}>
                    <input {...getInputProps()} id="fileUpload" />
                    <p className="text-gray-500">Drag 'n' drop a file here, or click to select a file</p>
                </div>
            </div>
            {selectedFile && (
                <div className="mt-4 p-4 bg-blue-100 text-blue-800 rounded-md shadow flex items-center">
                    <FaFileAlt className="mr-2" />
                    <span className="font-medium">Selected File: {selectedFile.name}</span>
                </div>
            )}
            <button onClick={handleUpload} disabled={!selectedFile || isLoading} className="bg-green-500 text-white font-bold py-2 px-4 rounded-lg mt-4">
                {isLoading ? 'Uploading...' : 'Upload File'} {/* Show loading text */}
            </button>
            {isLoading && (
                <div className="fixed inset-0 bg-white bg-opacity-80 flex justify-center items-center z-50">
                    <Lottie animationData={squirrelAnimation} style={{ width: 300, height: 300 }} />
                </div>
            )}
            {uploadSuccess && (
                <div className="mt-4 p-4 bg-green-200 text-green-800 rounded-md shadow">
                    <p>File uploaded successfully!</p>
                    <button onClick={handleReset} className="bg-blue-500 text-white font-bold py-1 px-3 rounded-lg mr-2">Upload Another</button>
                    <button onClick={() => navigate('/dashboard')} className="bg-gray-500 text-white font-bold py-1 px-3 rounded-lg">Go to Dashboard</button>
                </div>
            )}
        </div>
    );
}

export default AddDocumentPage;